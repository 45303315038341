










































































































import {Component, Mixins, Watch} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import {DappConnection} from '@/model/resource/DappConnection'
import {DappConnectionCollection} from '@/model/collection/DappConnectionCollection'
import {ListDappConnectionSchema} from '@/schema/resource/DappConnection/ListDappConnectionSchema'

@Component({})
export default class ListDappConnectionView extends Mixins(MixinRouteMatch) {
  schema = new ListDappConnectionSchema()
  collection = new DappConnectionCollection()

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: DappConnection) {
    this.$nav.pushByName('editDappConnection', item.$id)
  }

  async removeItem(item: DappConnection) {
    await this.$dialog.remove(item)
    await item.removeDappConnection()
    await this.collection.queryAsPage()
  }
}
